<template>
  <v-container>
    <v-row fluid>
      <v-col
        cols="12"
        sm="6"
        xm="12"
      >
        <v-card
          class="mt-4 mx-auto"
          max-width="500"
        >
          <v-sheet
            class="v-sheet--offset mx-auto"
            color="cyan"
            elevation="12"
            max-width="calc(100% - 32px)"
          >
            <v-sparkline
              :labels="labels"
              :value="value"
              color="white"
              line-width="2"
              padding="16"
            />
          </v-sheet>

          <v-card-text class="pt-0">
            <div class="title font-weight-light mb-2">
              Ordenes por Mes 2
            </div>
            <div class="subheading font-weight-light grey--text">
              Soporte Sait
            </div>
            <v-divider class="my-2" />

            <v-icon
              class="mr-2"
            >
              mdi-chart-box
            </v-icon>
            <span class="caption grey--text font-weight-light">Ordenes Acumulada por empresa.</span>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        xm="12"
      >
        <v-card
          class="mx-auto"
          color="grey lighten-4"
          max-width="500"
          height="250"
        >
          <v-card-title>
            <v-icon
              :color="checking ? 'red lighten-2' : 'indigo'"
              class="mr-12"
              size="64"
              @click="takePulse"
            >
              mdi-heart-pulse
            </v-icon>
            <v-row align="start">
              <div class="caption grey--text text-uppercase">
                Heart rate
              </div>
              <div>
                <span
                  class="display-2 font-weight-black"
                  v-text="avg || '—'"
                />
                <strong v-if="avg">BPM</strong>
              </div>
            </v-row>

            <v-spacer />

            <v-btn
              icon
              class="align-self-start"
              size="28"
            >
              <v-icon>mdi-arrow-right-thick</v-icon>
            </v-btn>
          </v-card-title>

          <v-sheet color="transparent">
            <v-sparkline
              :key="String(avg)"
              :smooth="16"
              :gradient="['#f72047', '#ffd200', '#1feaea']"
              :line-width="3"
              :value="heartbeats"
              auto-draw
              stroke-linecap="round"
            />
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <ordxmes />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import ordxmes from '@/views/graficas/ordxmes'

const exhale = ms =>
  new Promise(resolve => setTimeout(resolve, ms))

export default {
  components:{
    'ordxmes':ordxmes},

  data: () => ({
    checking: false,
    heartbeats: [],

    labels: [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic',


    ],
    value: [
      10,
      12,
      41,
      39,
      31,
      46,
      25,
      24,
      11,
      29,
      41,
      22
    ],

  }),

  computed: {
    avg () {
      const sum = this.heartbeats.reduce((acc, cur) => acc + cur, 0)
      const length = this.heartbeats.length

      if (!sum && !length) return 0

      return Math.ceil(sum / length)
    },
  },

  created () {
    this.takePulse(false)
  },

  methods: {
    heartbeat () {
      return Math.ceil(Math.random() * (120 - 80) + 80)
    },
    async takePulse (inhale = true) {
      this.checking = true

      inhale && await exhale(1000)

      this.heartbeats = Array.from({ length: 20 }, this.heartbeat)

      this.checking = false
    },
  },
};


   
</script>

<style>
  .v-sheet--offset {
    top: -24px;
    position: relative;
  }
</style>